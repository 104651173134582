import React, { useState, useEffect, useRef } from 'react'
import _debounce from 'lodash/debounce';
import { Toast } from 'primereact/toast';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import JSZip from 'jszip';

import Header from '../../components/Header';
import { clients_all } from '../../services/alarms';
import { sectorCreate } from '../../services/dashboard';
import { devices_filter_client, devices_localization_filter_client } from '../../services/sector';
import {
    FormContainer,
    StyledInput,
    StyledSelect,
    StyledMultiSelect,
    StyledButton,
    FileInput,
    Label,
    FileInputLabel,
    FileLabel,
    StyleTitle,
    ContainerButtonMap,
    StyledInputZ,
    LoaderWrapper
} from './styles';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MapCreateSector from '../../components/Maps/CreateSector';

import InpFileUploader from '../../components/FileUploadInp';
import { useNavigate, useParams } from 'react-router-dom';

const CreateSector = () => {
    const { id } = useParams();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [allClients, setAllClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState(null);
    const [devices, setDevices] = useState(null);
    const [macro, setMacro] = useState(null);
    const [refence, setReference] = useState(null);
    const [secondRefence, setSecondReference] = useState(null);
    const [thirdRefence, setThirdReference] = useState(null);
    const [nameSector, setNameSector] = useState('');
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState({
        sector: null,
        web: null
    });
    const [isCreate, setIsCreate] = useState(false);
    const [deleteSector, setDeleteSector] = useState(false);
    const [devicesList, setDevicesList] = useState(null);
    const [time, setTime] = useState('04:00');
    const [kmlData, setKmlData] = useState(null);
    const [pipes, setPipes] = useState(null);
    const [devicesMapTemp, setDevicesMapTemp] = useState([]);
    const [devicesMap, setDevicesMap] = useState([]);
    const [drawPath, setDrawPath] = useState([]);

    const [showLoader, setShowLoader] = useState(true);
    const [mandatoryItem, setMandatoryItem] = useState(false);

    useEffect(() => {
        debouncedGetAllClients();
        return debouncedGetAllClients.cancel;
    }, []);

    const debouncedGetAllClients = _debounce(() => {
        getAllClients();
    }, 1);

    useEffect(() => {
        getDevices();
    }, [selectedClient])

    useEffect(() => {
        setShowLoader(true);
        if (devicesMapTemp.length > 0 && devices && devices.length > 0) {
            const auxDevicesMap = devicesMapTemp;
            auxDevicesMap.forEach(deviceMap => {
                const matchingDevice = devices.find(device => device.id === deviceMap.device_id);
                if (matchingDevice) {
                    deviceMap.serial_number = matchingDevice.serial_number;
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 2000);
                }
            });
            setDevicesMap(auxDevicesMap);
        }
        if (devices && devices.length === 0) {
            setShowLoader(false);
        }
    }, [devicesMapTemp, devices]);

    const handleTimeChange = (time) => {
        setTime(time);
    };

    const getAllClients = async () => {
        try {
            const response = await clients_all();
            setAllClients(response.data)
            const clientObject = response.data.find(client => client.id === id);
            setSelectedClient(clientObject);
            getDevices();
        } catch (error) {
            if (error.response) {
                const msg = error.response.data.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
            } else if (error.request) {
                const message = error.message;
                toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
            }
        }
    };

    const getDevices = async () => {
        if (selectedClient && selectedClient.id) {
            getDevicesAndLocalization();
            try {
                const response = await devices_filter_client({ 'client_id': selectedClient.id });
                setDevices(response.data);
            } catch (error) {

            }
        }
    };

    const getDevicesAndLocalization = async () => {
        if (selectedClient && selectedClient.id) {
            try {
                const response = await devices_localization_filter_client({ 'client_id': selectedClient.id });
                setDevicesMapTemp(response.data);
            } catch (error) {

            }
        }
    };

    const handleSend = async () => {

        if (nameSector && nameSector.length > 0) {
            setMandatoryItem(false);
            const dvcList = [];

            if (devicesList) {
                devicesList.forEach(item => {
                    dvcList.push(item.device_id);
                });
            }
            const data = {
                "clientId": id,
                "sectorName": nameSector,
                "sectorCode": `Code ${nameSector}`,
                "callsNumber": 0,
                "mainInputPressPoint": refence,
                "secondEntryPressurePoint": secondRefence,
                "thirdEntryPressurePoint": thirdRefence,
                "secondaryPressCriticalPoint": null,
                "mainInputPressPointElevation": null,
                "hydroLoadTime": {
                    "start": null,
                    "end": null
                },
                "mvn_start": time,
                "info": {
                    "name": nameSector,
                    "client": id,
                    "refNode": null,
                    "netLen": null,
                    "netCnt": null,
                    "zmin": null,
                    "zmax": null
                },
                "macrometerList": [],
                "dvcList": dvcList,
                "sectorGeoDelimitation": drawPath,
                "sectorNetMap": file
            }

            try {
                await sectorCreate(data);
                toast.current.show({ severity: 'success', summary: 'Setor criado com sucesso.', life: 3000 });
                setTimeout(() => {
                    navigate('/manager-sector');
                }, 1500);
            } catch (error) {
                if (error.response) {
                    const msg = error.response.data.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
                } else if (error.request) {
                    const message = error.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
                }
            }
        }

        if (!nameSector || nameSector.trim().length === 0) {
            setMandatoryItem(true);
        }


    };


    const handleFileRead = (data) => {
        const updatedCoord = data;
        parseKML(updatedCoord);
    };

    const handleFileReadWeb = (e) => {
        const content = e.target.result;
        const informacoes = [];
        let coordenadasSection = false;

        const linhas = content.split('\n');

        for (const linha of linhas) {
            if (linha.startsWith('[PIPES]')) {
                coordenadasSection = true;
                continue;
            }

            if (linha.startsWith('[')) {
                coordenadasSection = false;
            }

            if (coordenadasSection && linha.trim() !== '' && !linha.startsWith(';')) {
                const elementos = linha.trim().split(/\s+/);
                if (elementos.length >= 4) {
                    const node1 = parseInt(elementos[1]);
                    const node2 = parseInt(elementos[2]);
                    const xCoord = parseFloat(elementos[3]);
                    const yCoord = parseFloat(elementos[4]);

                    informacoes.push({ node1, node2, xCoord, yCoord });
                }
            }
        }
        converterCoordenadas(informacoes);
    };

    function converterCoordenadas(dados) {
        const coordenadasConvertidas = [];

        // Fatores de escala para conversão
        const fatorEscalaLatitude = 0.00001; // Ajuste conforme a escala do seu mapa
        const fatorEscalaLongitude = 0.00001; // Ajuste conforme a escala do seu mapa

        for (let i = 0; i < dados.length; i++) {
            const xCoord = dados[i].xCoord * fatorEscalaLatitude - 21.587549590966816;
            const yCoord = dados[i].yCoord * fatorEscalaLongitude - 48.37032860896718;

            const x1Coord = dados[i].xCoord * fatorEscalaLatitude - 21.587646836876722;
            const y1Coord = dados[i].yCoord * fatorEscalaLongitude - 48.36994807490563;

            const novaEntrada = {
                n0: dados[i].node1,
                n1: dados[i].node2,
                x0: xCoord,
                x1: x1Coord,
                y0: yCoord,
                y1: y1Coord,
            };

            coordenadasConvertidas.push(novaEntrada);
        }
        setPipes(coordenadasConvertidas);
    }

    const handleDeletePolygon = () => {
        setDevicesMap([]);
        setDrawPath([]);
        setFile(null);
        setKmlData(null);
        setDeleteSector(true);
        setIsCreate(false);
        setFileName(prevFileName => ({ ...prevFileName, sector: '' }));
        setTimeout(() => {
            getDevices();
            setDeleteSector(false);
        }, 100);
    };

    const handlePath = async (data) => {
        // setKmlData(data);
        setDrawPath(data)
    }

    const handleDevices = (data) => {
        setDevicesList(data);
    }

    const parseKML = (content) => {
        setShowLoader(true);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/xml');
    
        const coordinatesNodes = xmlDoc.querySelectorAll('coordinates');
        const allCoordinates = [];
    
        coordinatesNodes.forEach(node => {
            const coordinatesText = node.textContent.trim();
            const coordinatesArray = coordinatesText.split(' ');
    
            const polygonCoordinates = coordinatesArray.map(coord => {
                const [longitude, latitude] = coord.split(',').map(parseFloat);
                return { latitude, longitude };
            });
    
            allCoordinates.push(polygonCoordinates);
        });
        setKmlData(allCoordinates);
        setTimeout(() => {
            setShowLoader(false);
        }, 2000);
    };

    const callReturn = () => {
        navigate('/manager-sector');
    };

    return (
        <>
            <Header />
            <Toast ref={toast} />
            <Container>
                {!isCreate && <StyleTitle>Criar Setor</StyleTitle>}
                <FormContainer>
                    <Row>
                        <Col style={{ marginTop: 14 }}>
                            {showLoader && <LoaderWrapper $size={'250px'} />}

                            <MapCreateSector data={devicesMap} isBig={isCreate} deletePolygon={deleteSector} onPath={handlePath} onDevices={handleDevices} kmlData={kmlData} tubes={pipes} />
                            <ContainerButtonMap $data={kmlData && kmlData.length > 0 ? true : false}>
                                <button disabled={kmlData && kmlData.length > 0 ? true : false} onClick={() => setIsCreate(!isCreate)}>{!isCreate ? 'Criar Polígono' : 'Fechar'}</button>
                                <button onClick={handleDeletePolygon}>Apagar Polígono</button>
                            </ContainerButtonMap>
                        </Col>
                        {!isCreate && <>
                            <Col>

                                <Label>Cliente</Label>
                                <span>{selectedClient && selectedClient.tradingName}</span>

                                <Label>Nome do Setor</Label>
                                <StyledInput $mandatary={mandatoryItem} type="text" onChange={(e) => setNameSector(e.target.value)} />
                                {mandatoryItem && <div style={{ display: 'flex', justifyContent: 'right' }}><label style={{ color: 'red', fontSize: 14 }}>Item Obrigatório !</label></div>}

                                <Label>1º Sensor de Referência</Label>
                                <StyledSelect value={refence} onChange={(e) => setReference(e.value)} options={devices} optionLabel="serial_number" optionValue="serial_number" placeholder="Selecione o 1º Sensor de Referência"
                                    filter className="w-full md:w-14rem" showClear emptyFilterMessage="Nenhum resultado encontrado" />

                                <Label>2º Sensor de Referência</Label>
                                <StyledSelect value={secondRefence} onChange={(e) => setSecondReference(e.value)} options={devices} optionLabel="serial_number" optionValue="serial_number" placeholder="Selecione o 2º Sensor de Referência"
                                    filter className="w-full md:w-14rem" showClear emptyFilterMessage="Nenhum resultado encontrado" />

                                <Label>3º Sensor de Referência</Label>
                                <StyledSelect value={thirdRefence} onChange={(e) => setThirdReference(e.value)} options={devices} optionLabel="serial_number" optionValue="serial_number" placeholder="Selecione o 3º Sensor de Referência"
                                    filter className="w-full md:w-14rem" showClear emptyFilterMessage="Nenhum resultado encontrado" />

                                <Label>Horário para cálculo de carga hidráulica</Label>
                                <div style={{ display: 'flex', justifyContent: 'start', height: '4%', marginBottom: 15 }}>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TimePicker
                                            onChange={handleTimeChange}
                                            value={time}
                                        />
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <Label>Área do setor</Label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FileInputLabel>
                                                Escolha um arquivo
                                                <input
                                                    type="file"
                                                    accept=".kmz"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        try {
                                                            const reader = new FileReader();
                                                            reader.onload = async (event) => {
                                                                const zip = new JSZip();
                                                                const zipFile = await zip.loadAsync(event.target.result);
                                                                const nameFile = Object.keys(zipFile.files);
                                                                const kmlContent = await zipFile.file(`${nameFile[0]}`).async('text');
                                                                setFileName(prevFileName => ({ ...prevFileName, sector: nameFile }));
                                                                handleFileRead(kmlContent);
                                                            };
                                                            reader.readAsArrayBuffer(file);
                                                        } catch (error) {
                                                            console.error('Erro ao ler o arquivo KMZ:', error);
                                                        }
                                                    }}
                                                />
                                            </FileInputLabel>
                                            <FileLabel> {fileName && fileName.sector && <div> {fileName.sector}</div>}</FileLabel>
                                        </div>
                                    </div>
                                    {/* Ainda está em discusão o mapa de rede */}
                                    {/* <div>
                                        <Label>Mapa da rede</Label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FileInputLabel>
                                                Escolha um arquivo
                                                <input
                                                    type="file"
                                                    accept=".inp"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onload = handleFileReadWeb;
                                                            reader.readAsText(file);
                                                        }
                                                    }}
                                                />
                                            </FileInputLabel>
                                            <FileLabel> {fileName && fileName.web && <div> {fileName.web}</div>}</FileLabel>
                                        </div>
                                    </div> */}
                                </div>
                            </Col>

                        </>}
                    </Row>
                </FormContainer>
                {/* <InpFileUploader /> */}
                {!isCreate && <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                    <StyledButton onClick={callReturn}>Cancelar</StyledButton>
                    <StyledButton $data={true} onClick={handleSend}>Salvar</StyledButton>
                </div>}
            </Container>
        </>
    )
}

export default CreateSector