import axios from 'axios'
import {API_URL as BASE_URL} from '../config/config';

const api = axios.create({
  baseURL: (BASE_URL),
  timeout: 15000,
})

const dologin = (data) => {
  return api.post('/4fluid/iot/kdi/auth/login', data);
};

export default dologin;
