import axios from 'axios'
import {API_URL as BASE_URL} from '../config/config';
const userData = JSON.parse(sessionStorage.getItem('@ada-user'));
const user = userData.state.user.token;
const api = axios.create({
  baseURL: (BASE_URL),
  timeout: 30000,
  headers: {'Authorization': `Bearer ${user}`},
})

export default api;