import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './styles/GlobalStyles';
import light from './styles/themes/light';
import Routes from './routes'

import { LoadScript } from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from "./config/config";

// import Layout from './components/Layout'
// import light from './styles/themes/light'
// import dark from './styles/themes/dark'

const App = () => {
  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY}  libraries={['drawing']}>
      <ThemeProvider theme={light}>
        <Routes />
        <GlobalStyles />
      </ThemeProvider>
    </LoadScript>
  )
}

export default App
