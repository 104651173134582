import React, { useState, useEffect, useRef } from 'react'
import _debounce from 'lodash/debounce';
import { Toast } from 'primereact/toast';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import JSZip from 'jszip';

import Header from '../../components/Header';
import { devices_filter_client, devices_localization_filter_client } from '../../services/sector';
import { sectorUpdate } from '../../services/dashboard';
import {
    FormContainer,
    StyledInput,
    StyledSelect,
    StyledMultiSelect,
    StyledButton,
    Label,
    FileInputLabel,
    FileLabel,
    StyleTitle,
    ContainerButtonMap,
    StyledInputZ,
    LoaderWrapper
} from './styles';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MapEditSector from '../../components/Maps/EditSector';

import { useNavigate, useParams, useLocation } from 'react-router-dom';

const EditSector = () => {
    const location = useLocation();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [devices, setDevices] = useState(null);
    const [refence, setReference] = useState(null);
    const [secondRefence, setSecondReference] = useState(null);
    const [thirdRefence, setThirdReference] = useState(null);
    const [nameSector, setNameSector] = useState('');
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState({
        sector: null,
        web: null
    });
    const [isCreate, setIsCreate] = useState(false);
    const [deleteSector, setDeleteSector] = useState(false);
    const [devicesList, setDevicesList] = useState(null);
    const [time, setTime] = useState('');
    const [kmlData, setKmlData] = useState(null);
    const [pipes, setPipes] = useState(null);
    const [devicesMap, setDevicesMap] = useState([]);
    const [devicesMapTotal, setDevicesMapTotal] = useState([]);
    const [sectorData, setSectorData] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [showLoader, setShowLoader] = useState(true);
    const [mandatoryItem, setMandatoryItem] = useState(false);


    useEffect(() => {
        setSectorData(location.state.data);
        setNameSector(location.state.data.sectorName);
        setDevices(location.state.data.dvcList);
        setReference(location.state.data.mainInputPressPoint && location.state.data.mainInputPressPoint.toString());
        setSecondReference(location.state.data.secondEntryPressurePoint && location.state.data.secondEntryPressurePoint.toString());
        setThirdReference(location.state.data.thirdEntryPressurePoint && location.state.data.thirdEntryPressurePoint.toString());
        setDevicesList(location.state.data.dvcList)
        setTime(location.state.data.mvnStart ? location.state.data.mvnStart : '');
        getDevicesAndLocalization();
        loadDrawPath(location.state.data.sectorGeoDelimitation);
        setTimeout(() => {
            setShowLoader(false);
        }, 2000);
    }, [location]);

    useEffect(() => {
        if (devices) {
            const result = devicesMapTotal.filter(device => selectedDevices.includes(device.serialNumber))
                .map(item => {
                    return {
                        "dvcId": item.device_id,
                        "validOnSectorFrom": item.validOnSectorFrom,
                        "activeCms": true,
                        "serialNumber": item.serialNumber
                    };
                });

            setSectorData(prevSectorData => ({
                ...prevSectorData,
                dvcList: result
            }));
        }
    }, [selectedDevices, devicesMapTotal, devices]);

    useEffect(() => {
        if (devices) {
            const result = devicesMapTotal.filter(device => selectedDevices.includes(device.serialNumber))
                .map(item => {
                    return {
                        "dvcId": item.device_id,
                        "validOnSectorFrom": item.validOnSectorFrom,
                        "activeCms": true,
                        "serialNumber": item.serialNumber,
                        "lat": item.lat,
                        "long": item.long
                    };
                });

            setDevicesMap(result);

        }
    }, [selectedDevices]);

    useEffect(() => {
        if (devices) {
            const result = devices.map(device => device.serialNumber);
            setSelectedDevices(result);
        }
    }, [devices]);

    const handleTimeChange = (time) => {
        setTime(time);
    };

    const getDevicesAndLocalization = async () => {
        if (location.state.data && location.state.data.clientId) {
            try {
                const responseLocalization = await devices_localization_filter_client({ 'client_id': location.state.data.clientId });
                const responseDevices = await devices_filter_client({ 'client_id': location.state.data.clientId });

                const localizationData = responseLocalization.data;
                const devicesData = responseDevices.data;

                localizationData.forEach(pointLocalization => {
                    devicesData.forEach(device => {
                        if (pointLocalization.device_id === device.id) {
                            pointLocalization.serialNumber = device.serial_number;
                            pointLocalization.validOnSectorFrom = device.updated_at;
                        }
                    });
                });

                const mappedDevices = [];
                location.state.data.dvcList.forEach(device => {
                    const foundDevice = localizationData.find(localizedDevice => localizedDevice.device_id === device.dvcId);
                    if (foundDevice) {
                        mappedDevices.push(foundDevice);
                    }
                });

                setDevicesMapTotal(localizationData);
                setDevicesMap(mappedDevices);
            } catch (error) {
                // Lidar com erros
            }
        }
    };

    const changeKey = coords => {
        if (coords) {
            coords.forEach(point => {
                point.lat = point.latitude;
                point.long = point.longitude;
                delete point.latitude;
                delete point.longitude;
            });
            return coords;
        }

        if (!coords) {
            return [];
        };
    };

    const handleSend = async () => {
        if (nameSector && nameSector.length > 0 && time) {
            setMandatoryItem(false);
            const dvcList = [];

            devicesList.forEach(item => {
                const newItem = {
                    dvcId: item.device_id,
                    serialNumber: item.serialNumber,
                    validOnSectorFrom: item.updated_at,
                    activeCms: true
                };
                dvcList.push(newItem);
            });

            const data = {
                "clientId": sectorData.clientId,
                "sectorName": nameSector,
                "sectorCode": `Code ${nameSector}`,
                "sectorId": sectorData.sectorId,
                "callsNumber": 0,
                "mainInputPressPoint": refence,
                "secondEntryPressurePoint": secondRefence,
                "thirdEntryPressurePoint": thirdRefence,
                "secondaryInputPressPont": sectorData.secondaryInputPressPont,
                "mainPressCritialPoint": null,
                "secondaryPressCriticalPoint": sectorData.secondaryPressCriticalPoint,
                "mainInputPressPointElevation": null,
                "hydroLoadTime": {
                    "start": null,
                    "end": null
                },
                "mvn_start": time,
                "info": {
                    "name": nameSector,
                    "client": sectorData.clientId,
                    "refNode": sectorData.info.refNode,
                    "netLen": sectorData.info.netLen,
                    "netCnt": sectorData.info.netCnt,
                    "zmin": null,
                    "zmax": null,
                },
                "macrometerList": sectorData.macrometerList,
                "dvcList": sectorData.dvcList,
                // "sectorGeoDelimitation": sectorData.sectorGeoDelimitation,
                "sectorGeoDelimitation": changeKey(kmlData),
                "sectorNetMap": sectorData.sectorNetMap
            }

            try {
                await sectorUpdate(data);
                toast.current.show({ severity: 'success', summary: 'Setor atualizado com sucesso.', life: 3000 });
                setTimeout(() => {
                    navigate('/manager-sector');
                }, 1500);

            } catch (error) {
                if (error.response) {
                    const msg = error.response.data.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 3000 });
                } else if (error.request) {
                    const message = error.message;
                    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
                }
            }
        }

        if (!nameSector || nameSector.trim().length === 0 || !time) {
            setMandatoryItem(true);
            return;
        }

    };

    const loadDrawPath = (data) => {
        if (data) {
            const updatedCoordinates = data.map(coord => {
                return {
                    "latitude": coord.lat,
                    "longitude": coord.long
                };
            });
            setKmlData(updatedCoordinates);
        }
    };

    const handleFileRead = (data) => {
        parseKML(data);
    };

    const handleFileReadWeb = (e) => {
        const content = e.target.result;
        const informacoes = [];
        let coordenadasSection = false;

        const linhas = content.split('\n');

        for (const linha of linhas) {
            if (linha.startsWith('[PIPES]')) {
                coordenadasSection = true;
                continue;
            }

            if (linha.startsWith('[')) {
                coordenadasSection = false;
            }

            if (coordenadasSection && linha.trim() !== '' && !linha.startsWith(';')) {
                const elementos = linha.trim().split(/\s+/);
                if (elementos.length >= 4) {
                    const node1 = parseInt(elementos[1]);
                    const node2 = parseInt(elementos[2]);
                    const xCoord = parseFloat(elementos[3]);
                    const yCoord = parseFloat(elementos[4]);

                    informacoes.push({ node1, node2, xCoord, yCoord });
                }
            }
        }
        converterCoordenadas(informacoes);
    };

    function converterCoordenadas(dados) {
        const coordenadasConvertidas = [];

        // Fatores de escala para conversão
        const fatorEscalaLatitude = 0.00001; // Ajuste conforme a escala do seu mapa
        const fatorEscalaLongitude = 0.00001; // Ajuste conforme a escala do seu mapa

        for (let i = 0; i < dados.length; i++) {
            const xCoord = dados[i].xCoord * fatorEscalaLatitude - 21.587549590966816;
            const yCoord = dados[i].yCoord * fatorEscalaLongitude - 48.37032860896718;

            const x1Coord = dados[i].xCoord * fatorEscalaLatitude - 21.587646836876722;
            const y1Coord = dados[i].yCoord * fatorEscalaLongitude - 48.36994807490563;

            const novaEntrada = {
                n0: dados[i].node1,
                n1: dados[i].node2,
                x0: xCoord,
                x1: x1Coord,
                y0: yCoord,
                y1: y1Coord,
            };

            coordenadasConvertidas.push(novaEntrada);
        }
        setPipes(coordenadasConvertidas);
    }

    const handleDeletePolygon = () => {
        setDevicesMap([]);
        setDevicesMapTotal([]);
        setFile(null);
        setKmlData(null);
        setDeleteSector(true);
        setFileName(prevFileName => ({ ...prevFileName, sector: '' }));
        setTimeout(() => {
            getDevicesAndLocalization();
            setDeleteSector(false);
        }, 100);
    };

    const handlePath = (e) => {
        setKmlData(e);
    }

    const handleDevices = (data) => {
        setDevicesList(data);
    }

    const parseKML = (content) => {
        setShowLoader(true);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(content, 'text/xml');
    
        const coordinatesNodes = xmlDoc.querySelectorAll('coordinates');
        const allCoordinates = [];
    
        coordinatesNodes.forEach(node => {
            const coordinatesText = node.textContent.trim();
            const coordinatesArray = coordinatesText.split(' ');
    
            const polygonCoordinates = coordinatesArray.map(coord => {
                const [longitude, latitude] = coord.split(',').map(parseFloat);
                return { latitude, longitude };
            });
    
            allCoordinates.push(polygonCoordinates);
        });
        setKmlData(allCoordinates);
        setTimeout(() => {
            setShowLoader(false);
        }, 2000);
    };

    const callReturn = () => {
        navigate('/manager-sector');
    };

    const handleDeviceSelection = (e) => {
        setSelectedDevices(e.value);
        setSelectAll(e.value.length === devices.length);
    };

    const handleSelectAllDevices = (e) => {
        setSelectedDevices(e.checked ? devices.map(device => device.serialNumber) : []);
        setSelectAll(!e.checked);
    };

    return (
        <>
            <Header />
            <Toast ref={toast} />
            <Container>
                {!isCreate && <StyleTitle>Editar Setor</StyleTitle>}
                <FormContainer>
                    <Row>
                        <Col style={{ marginTop: 14 }}>
                            {showLoader && <LoaderWrapper $size={'250px'} />}
                            <MapEditSector data={devicesMap} isBig={isCreate} deletePolygon={deleteSector} onPath={handlePath} onDevices={handleDevices} kmlData={kmlData} tubes={pipes} totalDvc={devicesMapTotal} />
                            <ContainerButtonMap $data={kmlData && kmlData.length > 0 ? true : false}>
                                <button onClick={() => setIsCreate(!isCreate)}>{!isCreate && !kmlData || !isCreate && kmlData && kmlData.length === 0 ? 'Criar Polígono' : !isCreate && kmlData && kmlData.length > 1 ? 'Editar Polígono' : 'Fechar'}</button>
                                <button onClick={handleDeletePolygon}>Apagar Polígono</button>
                            </ContainerButtonMap>
                        </Col>
                        {!isCreate && <>
                            <Col>
                                <Label>Nome do Setor</Label>
                                <StyledInput $mandatary={mandatoryItem} type="text" value={nameSector} onChange={(e) => setNameSector(e.target.value)} />
                                {mandatoryItem && <div style={{ display: 'flex', justifyContent: 'right' }}><label style={{ color: 'red', fontSize: 14 }}>Item Obrigatório !</label></div>}

                                <Label>1º Sensor de Referência</Label>
                                <StyledSelect value={refence} onChange={(e) => setReference(e.target.value)} options={devicesMapTotal} optionLabel="serialNumber" optionValue="serialNumber" placeholder="Selecione o Sensor de Referência"
                                    filter showClear className="w-full md:w-14rem" emptyFilterMessage="Nenhum resultado encontrado" />

                                <Label>2º Sensor de Referência</Label>
                                <StyledSelect value={secondRefence} onChange={(e) => setSecondReference(e.target.value)} options={devicesMapTotal} optionLabel="serialNumber" optionValue="serialNumber" placeholder="Selecione o Sensor de Referência"
                                    filter showClear className="w-full md:w-14rem" emptyFilterMessage="Nenhum resultado encontrado" />

                                <Label>3º Sensor de Referência</Label>
                                <StyledSelect value={thirdRefence} onChange={(e) => setThirdReference(e.target.value)} options={devicesMapTotal} optionLabel="serialNumber" optionValue="serialNumber" placeholder="Selecione o Sensor de Referência"
                                    filter showClear className="w-full md:w-14rem" emptyFilterMessage="Nenhum resultado encontrado" />

                                <Label>Editar Dispositivos do Setor </Label>
                                <StyledMultiSelect
                                    filter
                                    value={selectedDevices}
                                    options={devicesMapTotal ? devicesMapTotal.map(device => ({ label: device.serialNumber, value: device.serialNumber })) : []}
                                    onChange={handleDeviceSelection}
                                    selectAll={selectAll}
                                    onSelectAll={handleSelectAllDevices}
                                    virtualScrollerOptions={{ itemSize: 43 }}
                                    maxSelectedLabels={3}
                                    placeholder="Selecione Dispositivos"
                                    className="w-full md:w-20rem"
                                />

                                <Label>Horário para cálculo de carga hidráulica</Label>
                                <div style={{ display: 'flex', justifyContent: 'start', height: '4%', marginBottom: 15 }}>
                                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                        <TimePicker
                                            onChange={handleTimeChange}
                                            value={time}
                                        />
                                        {mandatoryItem && <div style={{ display: 'flex', justifyContent: 'right', marginLeft: 5 }}><label style={{ color: 'red', fontSize: 14 }}>Item Obrigatório !</label></div>}
                                    </div>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div>
                                        <Label>Área do setor</Label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FileInputLabel $data={false} $disabled={kmlData}>
                                                Escolha um arquivo
                                                <input
                                                    disabled={kmlData}
                                                    type="file"
                                                    accept=".kmz"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        try {
                                                            const reader = new FileReader();
                                                            reader.onload = async (event) => {
                                                                const zip = new JSZip();
                                                                const zipFile = await zip.loadAsync(event.target.result);
                                                                const nameFile = Object.keys(zipFile.files);
                                                                const kmlContent = await zipFile.file(`${nameFile[0]}`).async('text');
                                                                setFileName(prevFileName => ({ ...prevFileName, sector: nameFile }));
                                                                handleFileRead(kmlContent);
                                                            };
                                                            reader.readAsArrayBuffer(file);
                                                        } catch (error) {
                                                            console.error('Erro ao ler o arquivo KMZ:', error);
                                                        }
                                                    }}
                                                />
                                            </FileInputLabel>
                                            <FileLabel> {fileName && fileName.sector && <div> {fileName.sector}</div>}</FileLabel>
                                        </div>
                                    </div>
                                    {/* Atenção: Vai ser definido ainda como vai funcionar o Mapa de Rede */}
                                    {/* <div>
                                        <Label>Mapa da rede</Label>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <FileInputLabel $disabled={true}>
                                                Escolha um arquivo
                                                <input
                                                    disabled
                                                    type="file"
                                                    accept=".inp"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onload = handleFileReadWeb;
                                                            reader.readAsText(file);
                                                        }
                                                    }}
                                                />
                                            </FileInputLabel>
                                            <FileLabel> {fileName && fileName.web && <div> {fileName.web}</div>}</FileLabel>
                                        </div>
                                    </div> */}
                                </div>
                            </Col>

                        </>}
                    </Row>
                </FormContainer>
                {/* <InpFileUploader /> */}
                {!isCreate && <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                    <StyledButton $data={false} onClick={callReturn}>Cancelar</StyledButton>
                    <StyledButton $data={true} onClick={handleSend}>Salvar</StyledButton>
                </div>}
            </Container>
        </>
    )
}

export default EditSector