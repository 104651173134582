import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";


import Signin from "../pages/Signin";
import ForgotPassword from "../pages/ForgotPassword";

const AuthRoutes = () => (
    <Routes>
         <Route path="/" element={<Signin />} /> 
         <Route path="/forgot-password" element={<ForgotPassword />} />
         <Route path="*" element={<Navigate to="/" />} />
    </Routes>
);

export default AuthRoutes;