import React, { useEffect, useState, useCallback, useRef } from 'react'
import Header from '../../components/Header';
import {
  Container,
  GridContainer,
  SidebarContainer,
  List,
  ListItem,
  GridItem,
  GridItemTitle,
  TitleContainer,
  CompanyText,
  SectorText,
  GridItemSearch,
  GridItemInput,
  GridItemDate,
  GridItemSidebar,
  GridItemTable,
  TableItem,
  NewLine,
  TitleMap,
  TitleTable
} from './styles';
import { MdList, MdSearch, MdCancel, MdPinDrop } from 'react-icons/md'
import useUserStore from '../../store/useUserStore';
import useDateSelectedStore from '../../store/map/useDateSelectedStore';
import DateSelect from '../../components/DateSelect'
import CardsInformation from '../../components/CardsInformation';
import { FormattedMessage, useIntl } from 'react-intl';
import useStateSidebarStore from '../../store/useStateSidebarStore';
import useClientSelectedStore from '../../store/map/useClientSelectedStore';
import _debounce from 'lodash/debounce';
import pako from 'pako';
import { allSectors, pressure_data, general_infos, scheme } from "../../services/dashboard";
import RenderDeviceTable from "../../components/DeviceTable/index";

import InstallPointMap from '../../components/Maps/InstallPoint';

import { Toast } from 'primereact/toast';

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import useCompanyStore from '../../store/useCompanyStore';
import useCompanySectorStore from '../../store/useCompanySectorStore';
import useDropdownStore from '../../store/useDropdownStore';

const InstallPoints = () => {
  const intl = useIntl();
  const toast = useRef(null);
  const { tradingName } = useCompanyStore();
  const { user } = useUserStore();
  const { clientSelected } = useClientSelectedStore();
  const { disableDropdown, enableDropdown} = useDropdownStore();
  const { dateSelected } = useDateSelectedStore();
  const { setIsOpen } = useStateSidebarStore();
  const [expand, setExpand] = useState(true);
  const [indexSelected, setIndexSelected] = useState(0);
  const [itemSelected, setItemSelected] = useState(null);
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState('');
  const [listSectors, setListSectors] = useState([]);
  const [pressure, setPressure] = useState(null);
  const [info, setInfo] = useState(null);
  const [selectedSensor, setSelectedSensor] = useState(null);
  const [devices, setDevices] = useState(null);
  const [changeSize, setChangeSize] = useState(false);
  const [sectorNetMap, setSectorNetMap] = useState([]);
  const [flags, setFlags] = useState({
    map: false,
    cards: false,
  });
  const { setClientIdSelected, setSectorIdSelected, setStartDateSelected, setEndDateSelected } = useCompanySectorStore();

  useEffect(() => {
    disableDropdown();
    debouncedGetSectors();
    return debouncedGetSectors.cancel;
  }, [user, clientSelected]);

  useEffect(() => {
    disableDropdown();
    debouncedEffectSectors();
    return debouncedEffectSectors.cancel;
  }, [dateSelected, clientSelected, itemSelected]);

  useEffect(() => {
    if (filter.length > 0) {
      const result = listSectors.filter((setor) =>
        setor.sectorName.toLowerCase().includes(filter.toLowerCase())
      );
      setItems(result);
    } else {
      setItems(listSectors);
    }
  }, [filter, listSectors]);

  const debouncedGetSectors = _debounce(() => {
    if (user) {
      setIndexSelected(0);
      getSectors();
    }
  }, 500);

  const debouncedEffectSectors = _debounce(() => {
    const payload = {
      clientId: user.type_user === '1' ? clientSelected.id : user.client_id,
      dateSelected,
      sectorId: itemSelected?.sectorId
    };

    setClientIdSelected(payload.clientId);
    setSectorIdSelected(payload.sectorId);

    const allValuesAreTruthy = Object.values(payload).every(Boolean);

    if (allValuesAreTruthy) {
      dataChart(payload);
    }
  }, 1000);

  const getSectors = async () => {
    try {
      const payload = {
        'clientId': user.type_user === '1' && clientSelected.id ? clientSelected.id : user.client_id,
      }
      const response = await allSectors(payload);
      let filteredData = response.data.filter(sector => !sector.isMaster);
      setItems(filteredData);
      setListSectors(filteredData);
      selectItem(0, filteredData[0]);

      if (filteredData && filteredData.length === 0) {
        setItems([]);
        setListSectors([]);
        setDevices([]);
        setInfo([]);
      }

    } catch (error) {
      if (error.response) {
        const msg = error.response.data.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 10000 });
      } else if (error.request) {
        const message = error.message;
        toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 10000 });
      }
    }
  }

  const dataChart = async (data) => {
    if (itemSelected) {
      setFlags(prevFlags => ({ ...prevFlags, cards: false, map: false }));
      const intervalDays = data.dateSelected;
      const currentDate = new Date();
      const startDate = new Date(currentDate);
      startDate.setDate(startDate.getDate() - intervalDays);
      const finalStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
      const finalEndDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      setStartDateSelected(finalStartDate);
      setEndDateSelected(finalEndDate);
      
      try {
        const payload = {
          sectorId: data.sectorId,
          dti: finalStartDate,
          dtf: finalEndDate,
          clientId: data.clientId,
        };

        const [pressureRes, generalInfosRes, schemeRes] = await Promise.all([
          pressure_data(payload),
          general_infos(payload),
          scheme(payload)
        ]);

        const auxPressure = pressureRes.data.data;
        const tempPressure = JSON.parse(pako.ungzip(auxPressure, { to: 'string' }));
        setPressure(tempPressure);

        setInfo(generalInfosRes.data);
        setDevices(schemeRes.data?.devices);
        setSectorNetMap(schemeRes.data?.sectorNetMap);

        setFlags(prevFlags => ({ ...prevFlags, cards: true, map: true }));
        enableDropdown();
      } catch (error) {
        enableDropdown();
        if (error.response) {
          const msg = error.response.data.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: msg, life: 10000 });
        } else if (error.request) {
          const message = error.message;
          toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 10000 });
        }
        setFlags(prevFlags => ({ ...prevFlags, cards: false, map: false }));
      }
    }
  };

  const changeExpand = () => {
    setExpand(!expand);
    setIsOpen(expand);
  }

  const selectItem = (index, item) => {
    setChangeSize(false);
    setIndexSelected(index);
    setItemSelected(item);
  };

  const handleFiltroChange = (event) => {
    const valueTyped = event.target.value;
    setFilter(valueTyped);
  };

  const cleanSearch = () => {
    setFilter('');
    setItems(listSectors);
  }

  // const onSelectSensor = (sensorId) => {
  //   setSelectedSensor(prevSelectedSensor => sensorId === prevSelectedSensor ? null : sensorId);
  // };

  const onSelectSensor = (sensorId) => {
    if (sensorId == selectedSensor) {
      setSelectedSensor(null);
      setChangeSize(false);
    } else {
      setSelectedSensor(sensorId);
      setChangeSize(true);
    }
  }

  const p_devices = devices && Object.values(devices).filter(obj => obj.dataType === "pressure");

  var result = [];
  if (selectedSensor != null) {
    result = Object.values(devices).filter(item => item.deviceId === selectedSensor);
  } else {
    result = [];
  }

  return (
    <Container>
      <Toast ref={toast} />
      <Header />
      <GridContainer $sidebarOpen={expand} $size={changeSize}>
        <GridItemSearch $sidebarOpen={expand} >
          <MdList onClick={changeExpand} size={50} style={{ cursor: 'pointer' }} />
          {expand && <>
            <GridItemInput
              placeholder={intl.formatMessage({ id: "search_region" })}
              value={filter}
              onChange={handleFiltroChange}
            />
            {filter.length === 0 && <MdSearch size={25} />}
            {filter.length > 0 && <MdCancel size={25} onClick={cleanSearch} style={{ cursor: 'pointer' }} />}
          </>}
        </GridItemSearch>
        <GridItemTitle>
          <TitleContainer>
            <CompanyText>{tradingName}</CompanyText>
            <SectorText>{itemSelected?.sectorName}</SectorText>
          </TitleContainer>
        </GridItemTitle>
        <GridItemDate>
          <DateSelect show={false} />
        </GridItemDate>
        <GridItemSidebar $size={changeSize}>
          <SidebarContainer>
            <List>
              {items.map((item, index) => (
                <ListItem
                  key={index}
                  $active={index === indexSelected}
                  onClick={() => selectItem(index, item)}
                  $sidebarOpen={expand}
                >
                  {!expand ? "" : item.sectorName}
                  <MdPinDrop size={23} color={index === indexSelected ? ' #fff' : '#8086C0'} />
                </ListItem>
              ))}
              {items.length === 0 && <div style={{ textAlign: 'center', marginTop: 10, fontSize: 18 }}><FormattedMessage id="msg_no_data" /></div>}
            </List>
          </SidebarContainer>
        </GridItemSidebar>
        <TitleMap><FormattedMessage id="installed_sensors" /></TitleMap>
        <GridItem $size={changeSize} $skeleton={flags.map}>
          {flags.map && <InstallPointMap
            key={"flow_map"}
            devices={devices}
            traces={sectorNetMap}
            onSelectSensor={onSelectSensor}
            selectedSensor={selectedSensor}
          />}
        </GridItem>
        <TitleTable><FormattedMessage id="general_information_region" /></TitleTable>
        <GridItemTable $size={changeSize}>
          <TableItem $skeleton={flags.cards}>
            {flags.cards && <CardsInformation title={intl.formatMessage({ id: "network_extension" })} value={info && info.netLen ? info.netLen : intl.formatMessage({ id: "uninformed" })} icon='DISTANCE' size='normal' isSelected={selectedSensor} isKm={info && info.netLen ? true : false} />}
          </TableItem>
          <TableItem $skeleton={flags.cards}>
            {flags.cards && <CardsInformation title={intl.formatMessage({ id: "reference_sensor" })} value={info && info.refNode ? info.refNode : intl.formatMessage({ id: "uninformed" })} icon='REFERENCE' size='normal' isSelected={selectedSensor} />}
          </TableItem>
          <TableItem $skeleton={flags.cards}>
            {flags.cards && <CardsInformation title={intl.formatMessage({ id: "number_sensors" })} value={p_devices && p_devices.length} icon='DEVS' size='normal' isSelected={selectedSensor} />}
          </TableItem>
          <TableItem $skeleton={flags.cards}>
            {flags.cards && <CardsInformation title={intl.formatMessage({ id: "macro_meter" })} value={devices && devices.some(device => device.dataType === "flow") ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })} icon='MACRO' size='normal' isSelected={selectedSensor} />}
          </TableItem>
          <TableItem $skeleton={flags.cards}>
            {flags.cards && <CardsInformation title={intl.formatMessage({ id: "number_connections" })} value={info && info.netCnt ? info.netCnt : intl.formatMessage({ id: "uninformed" })} icon='HIDRO' size='normal' isSelected={selectedSensor} />}
          </TableItem>
        </GridItemTable>
        {changeSize && <NewLine>
          <RenderDeviceTable devices={devices} selectedSensor={selectedSensor} data={pressure} dateOn={new Date(new Date().setMonth(new Date().getMonth() - 1))} dateTo={new Date()} />
        </NewLine>}
      </GridContainer>
    </Container>
  )
}

export default InstallPoints;
