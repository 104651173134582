import axios from 'axios'
import {API_URL as BASE_URL} from '../config/config';

const api = axios.create({
  baseURL: (BASE_URL),
  timeout: 30000,
})

const forgotPassword = (data) => {
  return api.post('/4fluid/iot/kdi/auth/remember?origin=ada', data);
};

export default forgotPassword;